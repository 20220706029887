import SplinterlandsAPI from '../api/splinterlands'
import logger from '../utils/logger'

import { IBattleDetails } from '../types'

class BattleService {
  SL_API: SplinterlandsAPI
  user: string = ''

  constructor(user: string) {
    this.SL_API = new SplinterlandsAPI()
    this.user = user
  }

  async getBattleHistory() {
    const history = await this.SL_API.getUserBattleHistory(this.user)

    const battles = history.battles.map(battle => {
      const battleDetails: IBattleDetails = JSON.parse(battle.details)
      battle.parsedDetails = battleDetails
      return battle
    })

    history.battles = battles

    logger.log('getBattleHistory: ', history)
    return history
  }
}

export default BattleService