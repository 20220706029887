import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

import { IPlayerOutstandingMatch } from './core/types'

const RuleText = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.light,
  padding: theme.spacing(1),
  textAlign: 'center'
}))

const ChillingText = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.secondary.light,
  padding: theme.spacing(1),
}))

export default function CurrentMatch(props: {
  username: string,
  playerOutstandingMatch: IPlayerOutstandingMatch | null
}) {
  const { username, playerOutstandingMatch } = props

  const [playerInMatchStatus, setPlayerInMatchStatus] = useState('Getting the status.....')
  const [rulesets, setRulesets] = useState('Rulings')

  const currentMatchSetup = () => {
    if (!username) return

    if (!playerOutstandingMatch) {
      setPlayerInMatchStatus('Chilling')
      return
    }

    if (playerOutstandingMatch.tournament_format && playerOutstandingMatch.tournament_id) {
      setPlayerInMatchStatus('Tourney')
      return
    }

    if (playerOutstandingMatch.ruleset) {
      setRulesets(`(${playerOutstandingMatch.mana_cap}) ${playerOutstandingMatch.ruleset.split('|').join(' - ') || 'Standard'}`)
    }

    if (playerOutstandingMatch.team_hash) {
      setPlayerInMatchStatus('Ready for battle')
    } else {
      setPlayerInMatchStatus('Devicing a plan')
    }
  }

  useEffect(() => {
    currentMatchSetup()
  }, [playerOutstandingMatch])

  return (
    <Grid container>
      <Grid item xs={3}>
        <ChillingText>
          {playerInMatchStatus}
        </ChillingText>
      </Grid>
      <Grid item xs={9}>
        <RuleText>
          {rulesets}
        </RuleText>
      </Grid>
    </Grid>
  );
}
