import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'

import { IBattle } from '../core/types'
import { EDITION_NAMES, CARD_COLORS, COLOR_SPLINTER } from '../core/enums'
import CardService from '../core/services/cards'

const CardSize = {
  width: 150,
  height: 200
}

const IconSize = {
  width: 20,
  height: 20,
  marginRight: 10,
  marginLeft: 10,
}

interface IPastMatchProps {
  isOpen: boolean,
  handleClose: () => void,
  battleDetails: null | IBattle
}

export default function PastMatch(props: IPastMatchProps) {
  const { handleClose, isOpen, battleDetails } = props
  const theme = useTheme()

  if (!battleDetails) {
    return null
  }

  const cardService = new CardService()

  const inactiveMatchSplinters = battleDetails.inactive.split(',')

  const TeamContent = (props: { isTeam1: boolean }) => {
    const { isTeam1 } = props
    const team = isTeam1 ? battleDetails.parsedDetails?.team1 : battleDetails.parsedDetails?.team2

    if (!team) {
      return null
    }

    const summoner = team.summoner
    const summonerDetails = cardService.getLocalCardDetails(summoner.card_detail_id)

    return (
      <Grid container spacing={2} sx={{ marginTop: 1 }} textAlign={isTeam1 ? 'start' : 'end'}>
        <Grid item xs={12}>
          <Typography>{team.player}</Typography>
        </Grid>
        <Grid item xs={12}>
          <img
            style={CardSize}
            src={`https://d36mxiodymuqjm.cloudfront.net/cards_by_level/${EDITION_NAMES[summoner.edition]}/${encodeURI(summonerDetails.name)}_lv${summoner?.level ?? 1}${summoner.gold ? '_gold' : ''}.png`}
            alt={summonerDetails.name}
            loading="lazy"
          />
          {team.monsters ? team.monsters.map(monster => {
            const monsterDetails = cardService.getLocalCardDetails(monster.card_detail_id)
            return (
              <img
                key={`monster-${battleDetails.battle_queue_id_1}-${monster.card_detail_id}`}
                style={CardSize}
                src={`https://d36mxiodymuqjm.cloudfront.net/cards_by_level/${EDITION_NAMES[monster.edition]}/${encodeURI(monsterDetails.name)}_lv${monster.level}${monster.gold ? '_gold' : ''}.png`}
                alt={monsterDetails.name}
                loading="lazy"
              />  
            )
          }) : null}
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle sx={{ backgroundColor: theme.palette.info.dark }}>
        ({battleDetails.mana_cap}) - {battleDetails.ruleset.split('|').join(' - ')}
        {CARD_COLORS.map(color => {
          return inactiveMatchSplinters.includes(color) 
            ? null
            : (
              <img
                key={`allowed-color-${color}`}
                style={IconSize}
                src={`https://d36mxiodymuqjm.cloudfront.net/website/icons/icon-element-${COLOR_SPLINTER[color].toLowerCase()}-2.svg`}
                loading="lazy"
              />
            )
        })}
        
      </DialogTitle>
      <DialogContent>
        <TeamContent isTeam1={true} />
        <TeamContent isTeam1={false} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}