import React, { useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

import { IBattle } from './core/types'
import { EDITION_NAMES } from './core/enums'
import CardService from './core/services/cards'
import PastMatch from './partials/PastMatch'

interface IPastMatchesProps {
  battles: IBattle[],
  username: string
}

const CardSize = {
  width: 95,
  height: 140
}

const WinnerText = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.success.dark,
  color: theme.palette.success.light,
  padding: theme.spacing(1),
  cursor: 'pointer'
}))

const LoserText = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.error.dark,
  color: theme.palette.error.light,
  padding: theme.spacing(1),
  cursor: 'pointer'
}))

export default function PastMatches(props: IPastMatchesProps) {
  const [isMatchDetailsOpen, setIsMatchDetailsOpen] = useState(false)
  const [battleDetails, setBattleDetails] = useState<null | IBattle>(null)
  const cardService = new CardService()
  const { battles, username } = props

  const openMatchDetails = (battle: IBattle) => {
    setIsMatchDetailsOpen(true)
    setBattleDetails(battle)
  }

  const closeMatchDetails = () => {
    setIsMatchDetailsOpen(false)
  }

  const SingleBattle = (props: { battle: IBattle }) => {
    const { battle } = props
    const team = battle.player_1 === username ? battle.parsedDetails?.team1 : battle.parsedDetails?.team2
    const win = battle.winner === username ? true : false

    if (!team) {
      return null
    }

    const summoner = team.summoner
    const summonerDetails = cardService.getLocalCardDetails(summoner.card_detail_id)

    return (
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={8}>
          <Typography>({battle.mana_cap}) - {battle.ruleset.split('|').join(' - ')}</Typography>
        </Grid>
        <Grid item xs={4} textAlign="center">
          {win ? (
            <WinnerText onClick={() => openMatchDetails(battle)}>Winner</WinnerText>
          ) : (
            <LoserText onClick={() => openMatchDetails(battle)}>Loser</LoserText>
          )}
          
        </Grid>
        <Grid item xs={12}>
          <img
            style={CardSize}
            src={`https://d36mxiodymuqjm.cloudfront.net/cards_by_level/${EDITION_NAMES[summoner.edition]}/${encodeURI(summonerDetails.name)}_lv${summoner?.level ?? 1}${summoner.gold ? '_gold' : ''}.png`}
            alt={summonerDetails.name}
            loading="lazy"
          />
          {team.monsters ? team.monsters.map(monster => {
            const monsterDetails = cardService.getLocalCardDetails(monster.card_detail_id)
            return (
              <img
                key={`monster-${battle.battle_queue_id_1}-${monster.card_detail_id}`}
                style={CardSize}
                src={`https://d36mxiodymuqjm.cloudfront.net/cards_by_level/${EDITION_NAMES[monster.edition]}/${encodeURI(monsterDetails.name)}_lv${monster.level}${monster.gold ? '_gold' : ''}.png`}
                alt={monsterDetails.name}
                loading="lazy"
              />  
            )
          }) : null}
        </Grid>
      </Grid>
    )
  }

  return (
    <Container maxWidth="lg">
      <PastMatch isOpen={isMatchDetailsOpen} handleClose={closeMatchDetails} battleDetails={battleDetails} />
      {battles.map(battle => (
        <SingleBattle key={"battle-" + battle.battle_queue_id_1} battle={battle} />
      ))}
    </Container>
  );
}
