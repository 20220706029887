import SplinterlandsAPI from '../api/splinterlands'

import CardDetails from '../api/constants/card-details'
import { ICardCollection } from '../types'
import { CARD_XP_LVLS, GOLD_CARD_XP_LVLS, ALPHA_CARD_XP_LVLS, RARITY_NAMES } from '../enums'

class CardService {
  SL_API: SplinterlandsAPI
  user: string = ''

  constructor() {
    this.SL_API = new SplinterlandsAPI()
  }

  getLocalCardDetails(id: number): any {
    const detail = CardDetails.find(details => details.id === id)
    return detail ?? null
  }

  computeCardLevel(card: any) {
    const xp = card.xp
    const rarity = card.details.rarity
    const editions = card.details.editions
    const isGold = card.gold
    const tier = card.details.tier
    const rarityName = RARITY_NAMES[rarity]

    let level = 1

    if (editions.includes(0) 
      || editions.includes(1) 
      || editions.includes(2)
      || (editions.includes(3) && (!tier))
    ) {
      const rarityXpLvl = ALPHA_CARD_XP_LVLS[rarityName]

      rarityXpLvl.map((xpLvl: number, key: number) => {
        if (xp >= xpLvl) {
          level = key + 1
        }
      })
    } else {
      const rarityXpLvl = isGold ? GOLD_CARD_XP_LVLS[rarityName] : CARD_XP_LVLS[rarityName]

      rarityXpLvl.map((xpLvl: number, key: number) => {
        if (xp >= xpLvl) {
          level = key + 1
        }
      })
    }

    return level
  }

  async getCardDetails(uids: string[]) {
    const cardsDetails: any = []
    const cardLookupRes = await this.SL_API.getCardLookup(uids)

    cardLookupRes.map((card: any) => {
      const cardDetails: any = card.error 
        ? this.getLocalCardDetails(Number(card.uid))
        : this.getLocalCardDetails(Number(card.card_detail_id))

      cardDetails.level = card.player 
        ? this.computeCardLevel(card)
        : 1

      cardsDetails.push(cardDetails)
    })

    return cardsDetails
  }

  async getCardsCollection(user: string): Promise<ICardCollection[]> {
    const res = await this.SL_API.getCardsCollection(user)
    const final: ICardCollection[] = []

    res.cards.map((card: ICardCollection) => {
      const cardDetails = this.getLocalCardDetails(card.card_detail_id)
      if (cardDetails) {
        card.name = cardDetails.name
        card.card_details = cardDetails
        final.push(card)
      }
    })

    return final
  }
}

export default CardService