
import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { EDITION_NAMES } from './core/enums'


import { IBattle, ICardCollection } from './core/types'
import CardService from './core/services/cards'

interface ISummarries {
  battles: IBattle[],
  username: string
}

interface ISummonnerStats {
  details: ICardCollection,
  used: number
}

export default function Summarries(props: ISummarries) {
  const cardService = new CardService()
  const { battles, username } = props

  const [numBatlles, setNumBattles] = useState(5)
  const [summonerStats, setSummonerStats] = useState<ISummonnerStats[]>([])

  const computeSummary = () => {
    const stats: ISummonnerStats[] = []
    battles.slice(0, numBatlles).map(battle => {
      const team = battle.parsedDetails?.team1?.player === username ? battle.parsedDetails?.team1 : battle.parsedDetails?.team2

      if (!team) {
        return
      }

      const cardDetail = cardService.getLocalCardDetails(team?.summoner.card_detail_id ?? 0)
  
      if (!cardDetail) {
        return
      }

      cardDetail.level = team?.summoner?.level ?? 1
  
      const summonerStatKey = Object.keys(stats).find((k: string) => stats[Number(k)].details.name === cardDetail.name)
  
      if (!summonerStatKey) {
        stats.push({
          details: cardDetail,
          used: 1
        })
      } else {
        stats[Number(summonerStatKey)].used++
      }
    })
  
    stats.sort((a, b) => b.used - a.used)
    setSummonerStats(stats)
  }

  useEffect(() => {
    computeSummary()
  }, [battles])

  useEffect(() => {
    computeSummary()
  }, [numBatlles])

  const onClickShowSummarries = (num: number) => {
    setNumBattles(num)
  }

  return (
    <Container maxWidth="lg">
      <Grid container sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button onClick={() => onClickShowSummarries(5)}>Last 5</Button>
            <Button onClick={() => onClickShowSummarries(10)}>Last 10</Button>
            <Button onClick={() => onClickShowSummarries(20)}>Last 20</Button>
            <Button onClick={() => onClickShowSummarries(50)}>Last 50</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <ImageList cols={10}>
            {summonerStats.map((summoner: any) => {
              const card = summoner.details
              return card.name ? (
                <ImageListItem key={`summoner-id-${card.id}`}>
                  <img
                    src={`https://d36mxiodymuqjm.cloudfront.net/cards_by_level/${EDITION_NAMES[card.distribution[0].edition]}/${encodeURI(card.name)}_lv${card.level}.png`}
                    alt={card.name}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={summoner.used}
                    position="top"
                  />
                </ImageListItem>
              ) : null
            })}
          </ImageList>
        </Grid>
      </Grid>
    </Container>
  );
}
