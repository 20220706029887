import React, { useState, useEffect, useRef } from 'react'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

import PastMatches from './PastMatches'
import PlayerQuest from './PlayerQuest'
import Summarries from './Summarries'
import CurrentMatch from './CurrentMatch'
import CardCollections from './CardCollections'
import { IBattle, IPlayerOutstandingMatch } from './core/types'
import BattleService from './core/services/battle'
import PlayerService from './core/services/player'
import { Typography } from '@mui/material'

export default function App() {
  const [username, setUsername] = useState(process.env.REACT_APP_IN_ENV === 'dev' && process.env.REACT_APP_USERNAME ? process.env.REACT_APP_USERNAME : '')
  const [preUsername, setPreUsername] = useState('')
  const [battles, setBattles] = useState<IBattle[]>([])
  const [playerOutstandingMatch, setPlayerOutstandingMatch] = useState<IPlayerOutstandingMatch | null>(null)

  const timer = useRef<NodeJS.Timeout | null>(null)

  const onChangeUsername = (event: any) => {
    setPreUsername(event.target.value)
  }

  const onClickShow = () => {
    setUsername('')
    setUsername(preUsername)
  }

  const retrieveCurrentTeam = async () => {
    if (!username) return

    const playerService = new PlayerService(username)
    const currentMatch = await playerService.getPlayerOutstandingMatch()
    setPlayerOutstandingMatch(currentMatch)

    if (
      (!playerOutstandingMatch
        || (playerOutstandingMatch.team_hash && !playerOutstandingMatch.team) 
        || (playerOutstandingMatch.tournament_format && playerOutstandingMatch.tournament_id)
        || playerOutstandingMatch.teamParsed) 
        && timer.current) {
        clearInterval(timer.current)
    }
  }

  const init = async () => {
    const battleService = new BattleService(username)
    const battleHistory = await battleService.getBattleHistory()
    setBattles(battleHistory.battles)

    timer.current = setInterval(() => retrieveCurrentTeam(), 3 * 1000)
  }

  useEffect(() => {
    if (username) {
      init()
    }
  }, [username])

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ padding: 2 }}>
            <TextField size="small" onChange={onChangeUsername} id="outlined-basic" label="username" variant="outlined" />
            <Button variant="contained" onClick={onClickShow}>Show</Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={9}>
          {username ? (
            <CurrentMatch username={username} playerOutstandingMatch={playerOutstandingMatch}/>
          ) : null}
        </Grid>
        <Grid item xs={3}>
          <PlayerQuest username={username}/>
        </Grid>
      </Grid>
      
      {username && battles ? (
        <Grid container>
          <Grid item xs={12}>
            <Summarries username={username} battles={battles} />
          </Grid>

          <Grid item xs={6}>
            <CardCollections username={username} playerOutstandingMatch={playerOutstandingMatch}/>
          </Grid>
          <Grid item xs={6}>
            <PastMatches username={username} battles={battles}/>
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
}
