import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Grid from '@mui/material/Grid'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ButtonGroup from '@mui/material/ButtonGroup'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { useTheme } from '@mui/material/styles'

import CardService from './core/services/cards'
import { ICardCollection } from './core/types'
import { EDITION_NAMES, COLOR_SPLINTER, CARD_COLORS } from './core/enums'
import { IPlayerOutstandingMatch } from './core/types'

const IconSize = {
  width: 20,
  height: 20
}

export default function CardCollections(props: {
  username: string,
  playerOutstandingMatch: IPlayerOutstandingMatch | null
}) {
  const { username, playerOutstandingMatch } = props
  const cardService = new CardService()
  const theme = useTheme()

  const [cardsCollection, setCardsCollection] = useState<ICardCollection[]>([])
  const [cards, setCards] = useState<ICardCollection[]>([])
  const [colorsFilter, setColorsFilter] = useState<string[]>([])
  const [sortFilter, setSortFilter] = useState<string>('DESC_RARITY')
  const [attackFilter, setAttackFilter] = useState<string[]>([])
  const [manaFilter, setManaFilter] = useState('')
  const [editionFilter, setEditionFilter] = useState<number[]>([])
  const [removeDuplicates, setRemoveDuplicates] = useState(true)
  const [filterCardType, setFilterCardType] = useState<string[]>([])
  const [filterCardOwner, setFilterCardOwner] = useState<string[]>([])
  const [inactiveMatchSplinters, setInactiveMatchSplinters] = useState<string[]>([])

  const filterAndSort = () => {
    if (!cardsCollection.length) {
      return
    }

    let filteredCards: ICardCollection[] = []

    if (colorsFilter.length) {
      cardsCollection.map(card => {
        if (colorsFilter.includes(card.card_details?.color)) {
          filteredCards.push(card)
        }
      })
    } else {
      filteredCards = cardsCollection
    }

    const sorterdCardsByLevel: ICardCollection[] = filteredCards.sort((card1, card2) => {
      if (sortFilter === 'ASC_RARITY' && card1.card_details && card2.card_details) {
        if (card1.card_details.rarity > card2.card_details.rarity) {
          return 1
        }

        if (card1.card_details.rarity < card2.card_details.rarity) {
          return -1
        }
      } else if (sortFilter === 'DESC_RARITY' && card1.card_details && card2.card_details) {
        if (card1.card_details.rarity < card2.card_details.rarity) {
          return 1
        }

        if (card1.card_details.rarity > card2.card_details.rarity) {
          return -1
        }
      } else if (sortFilter === 'ASC_LEVEL') {
        if (card1.level > card2.level) {
          return 1
        }

        if (card1.level < card2.level) {
          return -1
        }
      } else if (sortFilter === 'DESC_LEVEL') {
        if (card1.level < card2.level) {
          return 1
        }

        if (card1.level > card2.level) {
          return -1
        }
      }

      return 0
    })

    let final: ICardCollection[] = []

    if (removeDuplicates) {
      sorterdCardsByLevel.map(card => {
        const duplicate = final.filter(finalCard => finalCard.card_detail_id === card.card_detail_id)
        if (duplicate.length > 0) {
          if (duplicate[0].level < card.level) {
            final.push(card)
          }
        } else {
          final.push(card)
        }
      })
    } else {
      final = sorterdCardsByLevel
    }

    if (filterCardType.length) {
      let filterCardTypes: ICardCollection[] = []
      final.map(finalCard => {
        if (filterCardType.includes(finalCard.card_details.type.toLowerCase())) {
          filterCardTypes.push(finalCard)
        }
      })

      final = filterCardTypes
    }

    if (filterCardOwner.length) {
      let filterCardOwners: ICardCollection[] = []
      final.map(finalCard => {
        if (filterCardOwner.includes('rented') && finalCard.is_delagated) {
          filterCardOwners.push(finalCard)
        }

        if (filterCardOwner.includes('owned') && !finalCard.is_delagated) {
          filterCardOwners.push(finalCard)
        }
      })

      final = filterCardOwners
    }

    if (attackFilter.length) {
      let filterCardAttacks: ICardCollection[] = []
      final.map(finalCard => {
        if ((attackFilter.includes('melee') && finalCard.card_details.stats.attack[finalCard.level - 1])
          || (attackFilter.includes('range') && finalCard.card_details.stats.ranged[finalCard.level - 1])
          || (attackFilter.includes('magic') && finalCard.card_details.stats.magic[finalCard.level - 1])
          || (attackFilter.includes('none')
            && !finalCard.card_details.stats.attack[finalCard.level - 1]
            && !finalCard.card_details.stats.ranged[finalCard.level - 1]
            && !finalCard.card_details.stats.magic[finalCard.level - 1])
        ) {
          filterCardAttacks.push(finalCard)
        }
      })

      final = filterCardAttacks
    }

    if (manaFilter) {
      let filterCardMana: ICardCollection[] = []
      final.map(finalCard => {
        const mana = finalCard.card_details.stats.mana[finalCard.level - 1]
        if (manaFilter === 'little' && mana < 5) {
          filterCardMana.push(finalCard)
        } else if (manaFilter === 'odd' && mana % 2 !== 0) {
          filterCardMana.push(finalCard)
        } else if (manaFilter === 'even' && mana % 2 === 0) {
          filterCardMana.push(finalCard)
        }
      })

      final = filterCardMana
    }

    if (editionFilter) {
      let filterEdition: ICardCollection[] = []
      final.map(finalCard => {
        filterEdition.push(finalCard)
      })

      final = filterEdition
    }
    
    setCards(final)
  }

  const retrieveCardCollection = async () => {
    const cards = await cardService.getCardsCollection(username)

    const playable: ICardCollection[] = []
    cards.map(card => {
      if (card.delegated_to === null || card.delegated_to === username) {
        card.is_delagated = card.player !== username ? true : false
        playable.push(card)
      }
    })

    setCardsCollection(playable)
    setCards(playable)
  }

  useEffect(() => {
    if (playerOutstandingMatch?.inactive) {
      const colors = playerOutstandingMatch.inactive.split(',')
      setInactiveMatchSplinters(colors)
      setColorsFilter(CARD_COLORS.filter(e => !colors.includes(e)))
    }
  }, [playerOutstandingMatch])

  useEffect(() => {
    retrieveCardCollection()
  }, [])

  useEffect(() => {
    filterAndSort()
  }, [editionFilter, manaFilter, attackFilter, filterCardOwner, colorsFilter, sortFilter, cardsCollection, removeDuplicates, filterCardType])

  const handleFilterCardType = (_event: any, cardType: string[]) => {
    setFilterCardType(cardType)
  }

  const handleFilterCardOwner = (_event: any, cardOwner: string[]) => {
    setFilterCardOwner(cardOwner)
  }

  const handleFilter = (_event: any, colors: string[]) => {
    setColorsFilter(colors)
  }

  const handleSortFilter = (_event: any, sort: string) => {
    setSortFilter(sort)
  }

  const handleAttackFilter = (_event: any, attacks: string[]) => {
    setAttackFilter(attacks)
  }

  const onRemoveDuplicates = (shouldRemove: boolean) => {
    setRemoveDuplicates(shouldRemove)
  }

  const onManaFilter = (filter: string) => {
    setManaFilter(filter)
  }

  const onEditionFilter = (editions: number[]) => {
    setEditionFilter(editions)
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <ToggleButtonGroup
            value={colorsFilter}
            onChange={handleFilter}
            aria-label="Filter By Splinter"
            size="small"
          >
            {CARD_COLORS.map(color => (
              <ToggleButton 
                key={`filter-color-${color}`}
                value={color}
                aria-label={COLOR_SPLINTER[color]}
                sx={
                  inactiveMatchSplinters.includes(color) ? {
                    backgroundColor: theme.palette.warning.main
                  } : {
                  }
                }
              >
                <img
                  style={IconSize}
                  src={`https://d36mxiodymuqjm.cloudfront.net/website/icons/icon-element-${COLOR_SPLINTER[color].toLowerCase()}-2.svg`}
                  loading="lazy"
                />
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={2}>
          <ToggleButtonGroup
            value={filterCardType}
            onChange={handleFilterCardType}
            aria-label="Filter By Type"
            size="small"
          >
            <ToggleButton value='summoner'>
              <img
                style={IconSize}
                src="https://d36mxiodymuqjm.cloudfront.net/website/icons/icon-type-summoner.svg"
                loading="lazy"
              />
            </ToggleButton>
            <ToggleButton value='monster'>
              <img
                style={IconSize}
                src="https://d36mxiodymuqjm.cloudfront.net/website/icons/icon-type-monster.svg"
                loading="lazy"
              />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={5}>
          <ToggleButtonGroup
            value={attackFilter}
            onChange={handleAttackFilter}
            aria-label="Sort"
            size="small"
          >
            <ToggleButton value="melee" aria-label="Melee">
              <img
                style={IconSize}
                src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png"
                loading="lazy"
              />
            </ToggleButton>
            <ToggleButton value="range" aria-label="Range">
              <img
                style={IconSize}
                src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged-attack.png"
                loading="lazy"
              />
            </ToggleButton>
            <ToggleButton value="magic" aria-label="Magic">
              <img
                style={IconSize}
                src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png"
                loading="lazy"
              />
            </ToggleButton>
            <ToggleButton value="none" aria-label="None">
              <Typography>0</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>


        {/* <Grid item xs={6}>
          <ToggleButtonGroup
            value={sortFilter}
            exclusive
            onChange={handleSortFilter}
            aria-label="Sort"
            size="small"
          >
            <ToggleButton value="ASC_LEVEL" aria-label="LVL: ASC">
              <Typography>LVL: ASC</Typography>
            </ToggleButton>
            <ToggleButton value="DESC_LEVEL" aria-label="LVL: ASC">
              <Typography>LVL: DESC</Typography>
            </ToggleButton>
            <ToggleButton value="ASC_RARITY" aria-label="LVL: ASC">
              <Typography>Rarity: ASC</Typography>
            </ToggleButton>
            <ToggleButton value="DESC_RARITY" aria-label="LVL: ASC">
              <Typography>Rarity: DESC</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid> */}

        <Grid item xs={4}>
          <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
            <Button onClick={() => onManaFilter('even')}>Even</Button>
            <Button onClick={() => onManaFilter('odd')}>Odd</Button>
            <Button onClick={() => onManaFilter('little')}>Little</Button>
            <Button onClick={() => onManaFilter('')}>All</Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={8}>
          <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
            <Button onClick={() => onEditionFilter([])}>Wild</Button>
            <Button onClick={() => onEditionFilter([])}>Modern</Button>
          </ButtonGroup>
        </Grid>
        
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={filterCardOwner}
            onChange={handleFilterCardOwner}
            aria-label="Filter By Splinter"
            size="small"
          >
            <ToggleButton value='rented'>
              <Typography>Rented</Typography>
            </ToggleButton>
            <ToggleButton value='owned'>
              <Typography>Owned</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={3}>
          <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
            <Button onClick={() => onRemoveDuplicates(false)}>Multiples</Button>
            <Button onClick={() => onRemoveDuplicates(true)}>Singles</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={3}>
          <Typography>Total Cards: {cards.length}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImageList cols={6}>
            {cards.map((card: ICardCollection) => {
              return card.name ? (
                <ImageListItem key={card.uid}>
                  <img
                    className={`${card.is_delagated ? 'delagated' : ''}`}
                    src={`https://d36mxiodymuqjm.cloudfront.net/cards_by_level/${EDITION_NAMES[card.edition]}/${encodeURI(card.name)}_lv${card.level}${card.gold ? '_gold' : ''}.png`}
                    alt={card.name}
                    loading="lazy"
                  />
                  {card.is_delagated ? (
                    <ImageListItemBar
                      title={card.player}
                      position="bottom"
                    />
                  ) : null}
                </ImageListItem>
              ) : null
            })}
          </ImageList>
        </Grid>
      </Grid>
    </Container>
  );
}
