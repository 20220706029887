export const SUMMONER_STATS_NAME_KEYS = ['attack', 'ranged', 'magic', 'armor', 'health', 'speed']
export const CARD_COLORS = ['Red', 'Blue', 'Green', 'White', 'Black', 'Gray', 'Gold']

export const SPLINTER_COLOR: any = {
  Fire: 'Red',
  Water: 'Blue',
  Earth: 'Green',
  Death: 'Black',
  Life: 'White',
  Neutral: 'Gray',
  Dragon: 'Gold'
}

export const COLOR_SPLINTER: any = {
  Red: 'Fire',
  Blue: 'Water',
  Green: 'Earth',
  Black: 'Death',
  White: 'Life',
  Gray: 'Neutral',
  Gold: 'Dragon'
}

enum QuestTypes {
  Life = 'Defend the Borders',
  Water = 'Pirate Attacks',
  Earth = 'Lyanna\'s Call',
  Fire = 'Stir the Volcano',
  Death = 'Rising Dead',
  Dragon = 'Gloridax Revenge',
  Neutral = 'Stubborn Mercenaries',
  Snipe = 'High Priority Targets',
  Stealth = 'Stealth Mission',
}

export enum CardRarities {
  Common = 1,
  Rare = 2,
  Epic = 3,
  Legendary = 4
}

export const RARITY_NAMES: any = {
  1: 'common',
  2: 'rare',
  3: 'epic',
  4: 'legendary'
}

export const EDITION_NAMES: any = {
  0: 'alpha',
  1: 'beta',
  2: 'promo',
  3: 'reward',
  4: 'untamed',
  5: 'dice',
  6: 'gladius',
  7: 'chaos'
}

export const CARD_XP_LVLS: any = {
  common: [
    1,
    5,
    14,
    30,
    60,
    100,
    150,
    220,
    300,
    400
  ],
  rare: [
    1,
    5,
    14,
    25,
    40,
    60,
    85,
    115
  ],
  epic: [
    1,
    4,
    10,
    20,
    32,
    46
  ],
  legendary: [
    1,
    3,
    6,
    11
  ]
}

export const GOLD_CARD_XP_LVLS: any = {
  common: [
    0,
    0,
    1,
    2,
    5,
    9,
    14,
    20,
    27,
    38
  ],
  rare: [
    0,
    1,
    2,
    4,
    7,
    11,
    16,
    22
  ],
  epic: [
    0,
    1,
    2,
    4,
    7,
    10
  ],
  legendary: [
    0,
    1,
    2,
    4
  ]
}

export const ALPHA_CARD_XP_LVLS: any = {
  common: [
    0,
    20,
    60,
    160,
    360,
    760,
    1560,
    2560,
    4560,
    7560
  ],
  rare: [
    0,
    100,
    300,
    700,
    1500,
    2500,
    4500,
    8500
  ],
  epic: [
    0,
    250,
    750,
    1750,
    3750,
    7750
  ],
  legendary: [
    0,
    1000,
    3000,
    7000
  ]
}