import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

import PlayerService from './core/services/player'

const CompletedQuestText = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.success.dark,
  color: theme.palette.success.light,
  padding: theme.spacing(1),
}))

const QuestText = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.info.dark,
  color: theme.palette.info.light,
  padding: theme.spacing(1),
}))

export default function PlayerQuest(props: { username: string }) {
  const { username } = props
  const [questName, setQuestName] = useState('')
  const [isCompleted, setIsCompleted] = useState(true)
  const [questRemaining, setQuestRemaining] = useState(0)

  const init = async () => {
    if (!username) return
    const playerService = new PlayerService(username)
    const playerQuest = await playerService.getPlayerQuest()
    setQuestName(playerQuest.questName)
    setIsCompleted(playerQuest.isCompleted)
    setQuestRemaining(playerQuest.remaining)
  }

  useEffect(() => {
    init()
  }, [username])

  if (!questName) return null

  return (
    <Grid item xs={12}>
      { !isCompleted ? (
        <CompletedQuestText>
          {questName}: {questRemaining}
        </CompletedQuestText>
      ) : (
        <QuestText>
          {questName}: {questRemaining}
        </QuestText>
      )}
    </Grid>
  );
}
