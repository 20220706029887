import axios from 'axios'

import logger from '../utils/logger'
import { IBattleHistory, IPlayerQuest, IPlayerOutstandingMatch, ICardLookup, ICardsCollection } from '../types'
import FakeBattleHistory from './fake-results/battle-history'
import FakePlayersQuests from './fake-results/players-quests'
import FakePlayerOutstandingMatch from './fake-results/players-outstanding-match'
import FakeCardsCollection from './fake-results/card-collections'

const host = 'https://api.splinterlands.io'

class SplinterlandsAPI {
  async getUserBattleHistory(user: string): Promise<IBattleHistory> {
    if (process.env.REACT_APP_IN_ENV === 'dev') {
      return FakeBattleHistory
    }
    
    const uri = `${host}/battle/history?player=${user}`
    const { data: response } = await axios.get(uri)
    logger.log(`GET ${uri}`, response)
    return response
  }

  async getUserQuest(user: string): Promise<IPlayerQuest[]> {
    if (process.env.REACT_APP_IN_ENV === 'dev') {
      return FakePlayersQuests
    }
    
    const uri = `${host}/players/quests?username=${user}`
    const { data: response } = await axios.get(uri)
    logger.log(`GET ${uri}`, response)
    return response
  }

  async getUserOutstandingMatch(user: string): Promise<IPlayerOutstandingMatch | null> {
    if (process.env.REACT_APP_IN_ENV === 'dev') {
      return FakePlayerOutstandingMatch
    }
    
    const uri = `${host}/players/outstanding_match?username=${user}`
    const { data: response } = await axios.get(uri)
    logger.log(`GET ${uri}`, response)
    return response
  }

  async getCardLookup(cardIds: string[]): Promise<ICardLookup[]> {
    const uri = `${host}/cards/find?ids=${cardIds.join(',')}`
    const { data: response } = await axios.get(uri)
    logger.log(`GET ${uri}`, response)
    return response
  }

  async getCardsCollection(user: string): Promise<ICardsCollection> {
    if (process.env.REACT_APP_IN_ENV === 'dev') {
      return FakeCardsCollection
    }
    
    const uri = `${host}/cards/collection/${user}`
    const { data: response } = await axios.get(uri)
    logger.log(`GET ${uri}`, response)
    return response
  }
}

export default SplinterlandsAPI