import SplinterlandsAPI from '../api/splinterlands'
import logger from '../utils/logger'

class PlayerService {
  SL_API: SplinterlandsAPI
  user: string = ''

  constructor(user: string) {
    this.SL_API = new SplinterlandsAPI()
    this.user = user
  }

  async getPlayerOutstandingMatch() {
    const playerOutstandingMatch = await this.SL_API.getUserOutstandingMatch(this.user)

    if (playerOutstandingMatch && playerOutstandingMatch.team) {
      playerOutstandingMatch.teamParsed = JSON.parse(playerOutstandingMatch.team)
    }

    return playerOutstandingMatch
  }

  async getPlayerQuest() {
    const playerQuests = await this.SL_API.getUserQuest(this.user)

    let isCompleted = true
    let questName = ''
    let remaining = 0

    playerQuests.map(quest => {
      questName = quest.name
      if (isCompleted && quest.completed_items !== quest.total_items) {
        isCompleted = false
        remaining = quest.total_items - quest.completed_items
      }
    })

    const final = {
      isCompleted,
      questName,
      remaining
    }

    logger.log('player quest: ', final)

    return final
  }
}

export default PlayerService